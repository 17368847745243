const getContainerStyles = (props) => ({
    byViewType: {
        card: {
            width: '428px',
            height: '310px',
            borderRadius: '12px',
            padding: `${props.theme.spacing_sizes.l}px`,
            backgroundColor: '#fff',
        },
        list: {
            width: '100%',
            height: 'auto',
            padding: `${props.theme.spacing_sizes.m}px ${props.theme.spacing_sizes.l}px`,
            borderRadius: '10px',
            backgroundColor: '#fff',
        },
        edit: {
            width: '100%',
            height: 'auto',
            padding: `${props.theme.spacing_sizes.l}px`,
            borderRadius: '10px',
            backgroundColor: props.theme.customColors.shades.primaryLight,
        },
    },
    byVariant: {
        plain: {},
        elevated: {
            boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.outline}`,
        },
    },
    byDisplayState: {
        default: {
            borderColor: props.theme.customColors.surface.outline,
            overlayColor: props.theme.customColors.portfolio.hover,
            overlayColorOnSelected: props.theme.palette.primary.main,
            backgroundColorOnSelected: props.theme.customColors.shades.primaryLight,
        },
        error: {
            borderColor: '#FFEAEA',
            overlayColor: '#E5484D',
            overlayColorOnSelected: '#E5484D',
            backgroundColorOnSelected: '#FFF6F6',
        },
    },
});
export default getContainerStyles;
