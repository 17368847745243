import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { useTranslation } from 'react-i18next';
import { ContainerRow, SurfaceColumn } from '@components/styled';
import { Body } from '@components/styled/Typography';
import OverlayContent from './OverlayContent';
import getContainerStyles from './styles';
import PortfolioBadge from './PortfolioBadge';
const OverlayContentContainer = styled(ContainerRow, { shouldForwardProp: (propName) => isPropValid(propName) })((props) => (Object.assign(Object.assign({ position: 'absolute', width: '100%', height: 'auto', left: 0, bottom: '-20px' }, props.viewType !== 'card' && ({
    bottom: '-34px',
})), { zIndex: 1 })));
const Container = styled(SurfaceColumn, { shouldForwardProp: (propName) => isPropValid(propName) })((props) => (Object.assign(Object.assign({ position: 'relative', boxSizing: 'border-box', flexDirection: props.viewType === 'card' ? 'column' : 'row', alignItems: props.viewType === 'card' ? 'baseline' : 'center', justifyContent: props.viewType === 'card' ? 'start' : 'space-between', width: getContainerStyles(props).byViewType[props.viewType].width, height: getContainerStyles(props).byViewType[props.viewType].height, padding: getContainerStyles(props).byViewType[props.viewType].padding, borderColor: getContainerStyles(props).byDisplayState[props.displayState].borderColor, borderRadius: getContainerStyles(props).byViewType[props.viewType].borderRadius, boxShadow: getContainerStyles(props).byVariant[props.variant].boxShadow, backgroundColor: getContainerStyles(props).byViewType[props.viewType].backgroundColor }, props.displayOverlay && (Object.assign({ outline: `2px solid ${getContainerStyles(props).byDisplayState[props.displayState].overlayColor}`, outlineOffset: '-2px' }, props.displayState !== 'error' && ({
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
})))), props.isSelected && ({
    outline: `2px solid ${getContainerStyles(props).byDisplayState[props.displayState].overlayColorOnSelected}`,
    outlineOffset: '-2px',
    backgroundColor: getContainerStyles(props)
        .byDisplayState[props.displayState]
        .backgroundColorOnSelected,
    boxShadow: `0px 2px 10px -6px ${props.theme.customColors.surface.outline}`,
}))));
const PortfolioContainer = ({ children, portfolio, variant, viewType, displayState, selected = false, disabled = false, alwaysDisplayOverlay = false, onClick = () => { }, onMouseEnter = undefined, onMouseLeave = undefined, className = undefined, isHovered: isForceHovered = false, customBadgeText = undefined, badgeStyles = {}, }) => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const onMouseOver = () => {
        setIsHovered(true);
        if (onMouseEnter) {
            onMouseEnter(portfolio.id);
        }
    };
    const onMouseOut = () => {
        setIsHovered(false);
        if (onMouseLeave) {
            onMouseLeave(portfolio.id);
        }
    };
    const displayOverlay = alwaysDisplayOverlay ||
        (!disabled && !selected && isHovered) ||
        isForceHovered;
    const badgeVariant = useMemo(() => {
        if (portfolio.is_archived) {
            return 'archive';
        }
        if (portfolio.is_in_assessment_review) {
            return 'info';
        }
        if (portfolio.has_error) {
            return 'error';
        }
        return undefined;
    }, [portfolio]);
    const badgeContent = useMemo(() => {
        let content;
        if (customBadgeText === null || customBadgeText === void 0 ? void 0 : customBadgeText.length) {
            content = (_jsx(Body, { children: customBadgeText }));
            return content;
        }
        switch (badgeVariant) {
            case 'info':
                content = (_jsx(Body, { children: `${t('portfolio.assessment_review')}: 3 ${t('periods.day', { ns: 'common', count: 3 })}` }));
                break;
            case 'archive':
                content = (_jsx(Body, { children: t('portfolio.history') }));
                break;
            case 'error':
                content = (_jsx(Body, { children: `3 ${t('periods.day', { ns: 'common', count: 3 })} 15:09:31` }));
                break;
            default:
        }
        return content;
    }, [badgeVariant, customBadgeText, t]);
    return (_jsxs(Container, { className: className, variant: variant, viewType: viewType, isSelected: selected, displayState: displayState, displayOverlay: displayOverlay, onClick: onClick, onMouseOver: onMouseOver, onMouseOut: onMouseOut, children: [badgeVariant && (_jsx(PortfolioBadge, { variant: badgeVariant, isHovered: selected || displayOverlay, styles: badgeStyles, children: badgeContent })), children, displayState !== 'error' &&
                displayOverlay &&
                (_jsx(OverlayContentContainer, { viewType: viewType, children: _jsx(OverlayContent, { portfolio: portfolio, viewType: viewType }) }))] }));
};
export default PortfolioContainer;
